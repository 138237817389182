var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: {
          passedActions: _vm.titleBarActions,
          title: "Edit Sublocation",
        },
      }),
      _c("span", { staticClass: "required-bar" }),
      _vm.error
        ? _c("span", { staticClass: "partner-form-error-bar" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      _c("SublocationForm", {
        attrs: {
          sublocation: _vm.sublocation,
          allSublocations: _vm.partner.cursusPartnerSubLocations,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }